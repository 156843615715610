.container {
  display: flex;
  align-items: flex-start;
  max-height: 500px; /* adjust as needed */
}

.componentArea {
  border-radius: 5px;
  padding: 10px;
  margin-top: 0px;
}

.legends {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 10px;
}

.legend-item {
  height: 20px;
  line-height: 30px;
  font-family: Arial, sans-serif;
  position: relative;
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 600;
}

.gantt {
  position: relative;
  flex: 1;
  height: auto;
}

.track {
  position: absolute;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: width 2s ease-out;
  left: 0;
}

.track.active {
  background-color: rgba(133, 181, 38, 0.3);
}

.task {
  margin-top: 12.5px;
  margin-bottom: 12.5px;
  position: absolute;
  height: 20px;
  border-radius: 5px;
  color: #000000;
  text-align: center;
  line-height: 30px;
  font-family: Arial, sans-serif;
  font-size: 10px;
  margin-bottom: 5px;
  transition:
    width 0.5s ease-out,
    left 0.5s ease-out;
}

.dates {
  display: flex;
  justify-content: space-between;
  font-family: Arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
}

.year {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.months {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.month {
  text-align: center;
  flex: 1;
}

/* Tooltip styling */
.task[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  padding: 5px;
  border-radius: 5px;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
}

.task[data-tooltip]:hover::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 1) transparent transparent transparent;
  z-index: 1000;
  pointer-events: none;
}
