.MuiAutocomplete-popper {
  width: 330px !important;
}

.MuiAutocomplete-hasPopupIcon.css-1v6lxil-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1v6lxil-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-left: 4px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.no-border.MuiOutlinedInput-root {
  border-radius: 4px 0 0 4px !important;
}

.btn-border.MuiButton-root {
  border-radius: 0 4px 4px 0 !important;
}
